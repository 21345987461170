import type { NextPage } from 'next'
import Head from 'next/head'
import { useIntl } from 'react-intl'

import { HomePageView } from '@components/homepage'

const Home: NextPage = () => {
  const intl = useIntl()

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: 'homepage.title',
            defaultMessage: 'Homepage Title',
          })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({
            id: 'homepage.description',
            defaultMessage: 'Homepage Description',
          })}
        />
      </Head>

      <HomePageView />
    </>
  )
}

export default Home
